import React, { useState } from 'react';
import { FaEnvelope, FaLock } from 'react-icons/fa';
import Link from 'next/link';
import useAuth from '../../../utils/hooks/useAuth';
import { isEmpty } from 'lodash';

type Props = {};
const logInLoadingIndicator = () => {
    return (
        <>
            <div className="w-4 h-4 bg-white animate-ping rounded-full flex items-center justify-center">
                <div className="w-2 h-2 bg-white animate-ping rounded-full flex items-center justify-center"></div>
            </div>
            Logging In...
        </>
    );
};

const Login = (props: Props) => {
    const [loginFields, setLoginFields] = useState({
        email: '',
        password: '',
    });

    const { login, error, status } = useAuth();
    const loggingIn = status === 'resolving';

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        login(loginFields.email, loginFields.password);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //update form state
        setLoginFields((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    return (
        <form className="space-y-3" onSubmit={onSubmit}>
            {!isEmpty(error) && <p className="text-brand-red">{error}</p>}
            <div className="relative">
                <input
                    onChange={onChange}
                    name="email"
                    placeholder="Email*"
                    className="h-12 appearance-none w-full bg-white border border-brand-primary rounded-sm shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-brand-primary focus:border-brand-primary focus:placeholder-gray-400"
                    type="text"
                    disabled={loggingIn}
                />
                <FaEnvelope className="pointer-events-none absolute top-3.5 right-4 h-5 w-5 text-brand-primary" />
            </div>

            <div className="relative">
                <input
                    onChange={onChange}
                    name="password"
                    autoComplete="current-password"
                    placeholder="Password*"
                    className="h-12 appearance-none w-full bg-white border border-brand-primary rounded-sm shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-brand-primary focus:border-brand-primary focus:placeholder-gray-400"
                    type="password"
                    disabled={loggingIn}
                />
                <FaLock className="pointer-events-none absolute top-3.5 right-4 h-5 w-5 text-brand-primary" />
            </div>
            <div className="flex justify-end">
                <a
                    href="/my-account/lost-password/"
                    className="text-brand-primary text-sm font-semibold text-right focus:ring-brand-primary focus:ring-2 focus:outline-none"
                >
                    Forgot Password
                </a>
            </div>
            <button
                className="flex justify-center items-center gap-5 flex-1 uppercase w-full button-gold-solid text-white h-12 py-3  tracking-wider"
                disabled={loggingIn}
            >
                {loggingIn ? logInLoadingIndicator() : 'Login'}
            </button>
            <div>
                <Link
                    href="/registration-redirect/"
                    className="uppercase text-sm font-semibold button-black-outline block w-full text-center h-12 py-3 tracking-wider"
                >
                    Register
                </Link>
            </div>
        </form>
    );
};

export default Login;
