import menuItems from '../../../data/menuItems.json';
import { isEmpty, isArray } from 'lodash';
import Link from 'next/link';
import MegaMenu from '../../../components/layout/header/MegaMenu';
import { GbMenuCarret } from '../../../components/icons';
import React from 'react';

import { Disclosure } from '@headlessui/react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import Login from './Login';
import { useSiteContext } from '../../../contexts/SiteContext';
import useIsLoggedIn from '../../../utils/hooks/useIsLoggedIn';
import { cn } from '../../../utils';

export default function MainMenu() {
  const { isMobileMenuOpen, isMegaMenuOpen, setIsMegaMenuOpen } =
    useSiteContext();

  const isLoggedIn = useIsLoggedIn();
  return (
    <>
      <div className="bg-brand-primary-dark text-white hidden xl:!block">
        {!isEmpty(menuItems) && isArray(menuItems) ? (
          <ul className="container flex flex-col justify-evenly xl:flex-row ">
            {menuItems.map((menuItem, i) => (
              <li
                key={i}
                className={isMegaMenuOpen ? 'group' : ''}
                onMouseEnter={() => setIsMegaMenuOpen(true)}
                onMouseLeave={() => setIsMegaMenuOpen(false)}
              >
                <Link
                  href={menuItem?.slug ?? '/'}
                  className={`flex items-center gap-2 py-2.5 px-[30px] relative uppercase hover:text-brand-primary not-italic font-semibold text-[13px] tracking-widest`}
                >
                  {menuItem?.title} {menuItem.columns ? <GbMenuCarret /> : ''}
                </Link>
                {menuItem.columns ? (
                  <MegaMenu columns={menuItem.columns} />
                ) : (
                  ''
                )}
              </li>
            ))}
          </ul>
        ) : null}
      </div>

      {/* Mobile Menu */}
      <div className="w-full xl:hidden">
        <div
          className={`mx-auto w-full rounded-2xl ${
            !isMobileMenuOpen ? 'hidden' : ''
          }`}
        >
          {!isEmpty(menuItems) && isArray(menuItems) ? (
            <>
              {menuItems.map((menuItem, i) => (
                <div key={i}>
                  {!menuItem.columns ? (
                    <a
                      className="uppercase flex w-full justify-between bg-brand-primary-dark px-4 py-4 text-left text-sm font-semibold leading-4 tracking-widest text-white hover:bg-[#ECECEC] hover:text-brand-primary focus:outline-none focus-visible:ring focus-visible:ring-brand-primary focus-visible:ring-opacity-75"
                      href={menuItem?.slug ?? '/'}
                      key={i}
                    >
                      {menuItem?.title}
                    </a>
                  ) : (
                    <Disclosure key={i}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={cn(
                              'uppercase flex w-full justify-between bg-brand-primary-dark px-4 py-4 text-left text-sm font-semibold leading-4 tracking-widest text-white hover:text-brand-primary focus:outline-none focus-visible:ring focus-visible:ring-brand-primary focus-visible:ring-opacity-75',
                              open ? 'hover:bg-[#ECECEC]' : '',
                            )}
                          >
                            <span>{menuItem?.title}</span>
                            {open ? <FaCaretDown /> : <FaCaretUp />}
                          </Disclosure.Button>
                          <Disclosure.Panel className="te">
                            <MegaMenu columns={menuItem.columns} />
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )}
                </div>
              ))}

              {isLoggedIn ? (
                <Link
                  href="/my-account"
                  className="uppercase flex w-full justify-between bg-brand-primary-dark px-4 py-4 text-left text-sm font-semibold leading-4 tracking-widest text-white hover:text-brand-primary focus:outline-none focus-visible:ring focus-visible:ring-brand-primary focus-visible:ring-opacity-75"
                >
                  My Account
                </Link>
              ) : (
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={cn(
                          'uppercase flex w-full justify-between bg-brand-primary-dark px-4 py-4 text-left text-sm font-semibold leading-4 tracking-widest text-white hover:text-brand-primary focus:outline-none focus-visible:ring focus-visible:ring-brand-primary focus-visible:ring-opacity-75',
                          open ? 'hover:bg-[#ECECEC]' : '',
                        )}
                      >
                        <span>Login</span>
                        {open ? <FaCaretDown /> : <FaCaretUp />}
                      </Disclosure.Button>

                      <Disclosure.Panel className="te">
                        <div className="px-10 py-10 bg-[#ececec]">
                          <Login />
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
