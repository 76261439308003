import Link from 'next/link';
import Image from '../../../components/common/Image';
import React from 'react';
const columnTitleClass = 'font-montserrat-900 font-bold text-[13px] mb-[5px]';

export const List = ({ item }) => {
    if (item.items.length == 0) {
        return null;
    }

    return (
        <ul>
            {item.items.map((item, i) => {
                return (
                    <li className="leading-7 mt-[5px]" key={i}>
                        {item.type && item.type == 'column_style' ? (
                            <h4 className={`mt-4 ${columnTitleClass}`}>
                                {item.title}
                            </h4>
                        ) : (
                            <Link href={item.slug} className="text-sm">
                                {item.title}{' '}
                                {item.isNew && (
                                    <span className="inline-flex items-center rounded-full bg-brand-primary px-3 py-0.5 text-sm font-medium text-white ml-1">
                                        New
                                    </span>
                                )}
                            </Link>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

export const Button = ({ item }) => {
    return (
        <Link
            href={item.href}
            className="m1 text-[13px] font-normal bg-brand-primary text-white hover:bg-black py-4 px-5 mt-5 leading-[4]"
        >
            {item.title}
        </Link>
    );
};

export const Text = ({ item }) => {
    return <p className="text-brand-grey text-sm font-light">{item.text}</p>;
};

export const ImgElement = ({ item }) => {
    return (
        <Image
            src={item.src}
            alt={item.alt}
            width={item.width}
            height={item.height}
			className='py-1'
        />
    );
};

export const ImgElementWithLink = ({ item }) => {
    if (!item.href) return <ImgElement item={item} />;

    return (
        <Link href={item.href}>
            <ImgElement item={item} />
        </Link>
    );
};

export const Html = ({ item }) => {
    return <div>Html</div>;
};

export const LabelWithLink = ({ item }) => {
    return (
        <p>
            <span className="text-brand-grey text-sm">{item.label}</span>{' '}
            <Link href={item.href} className="text-[13px] font-normal ">
                {item.link_text}
            </Link>
        </p>
    );
};

export const RenderMenuItemElement = ({ item }) => {
    switch (item.type) {
        case 'list':
            return <List item={item} />;
        case 'button_style':
            return <Button item={item} />;
        case 'text':
            return <Text item={item} />;
        case 'image':
            return <ImgElementWithLink item={item} />;
        case 'html':
            return <Html item={item} />;
        case 'label_with_link':
            return <LabelWithLink item={item} />;
        default:
            return <List item={item} />;
    }
};

export default function MegaMenu({ columns }) {
    const generateColumnsClass = (columns) => {
        let colClasses: string[] = [];
        colClasses[5] = 'xl:grid-cols-5';
        colClasses[4] = 'xl:grid-cols-4';
        colClasses[3] = 'xl:grid-cols-3';
        colClasses[2] = 'xl:grid-cols-2';

        return `grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:grid-cols-${columns.length} xl:gap-x-1`;
    };

    const columnsClass = generateColumnsClass(columns);

    return (
        <div className="bg-red-50 xl:flex xl:justify-center xl:max-w-[1260px] xl:mx-auto xl:absolute left-0 right-0 transition xl:group-hover:translate-y-0 xl:translate-y-5 xl:opacity-0 xl:invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50  transform">
            <div className="max-w-[1260px] mx-auto relative top-0 px-5 py-2.5 bg-[#ececec] w-full">
                <div className="relative z-10">
                    <div className={columnsClass}>
                        {columns.length
                            ? columns.map((column, i) => {
                                  return (
                                      <div
                                          key={i}
                                          className={`p-3 relative text-brand-secondary `}
                                      >
                                          <h4 className={`${columnTitleClass}`}>
                                              {column.title}
                                          </h4>
                                          {column.items.length
                                              ? column.items.map((item, i) => {
                                                    return (
                                                        <RenderMenuItemElement
                                                            item={item}
                                                            key={i}
                                                        />
                                                    );
                                                })
                                              : null}
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                </div>
            </div>
        </div>
    );
}
